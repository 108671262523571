import mainImage from './assets/basketball.png';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faHeadset, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { chatWithAI, systemMessage, disclaimerMessage } from './Api';
import { BeatLoader } from "react-spinners";

function App() {

  const [chatOpen, setChatOpen] = useState(false);
  const [chatClosing, setChatClosing] = useState(false);
  const [messages, setMessages] = useState([]);
  const lastMessageRef = useRef(null);
  const lastMessageRefLeft = useRef(null);
  const lastMessageRefRight = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialDataLoading, setIsInitialDataLoading] = useState(true);
  const [aiResponse, setAiResponse] = useState('');
  const [message, setMessage] = useState('');
  const loaderRef = useRef(null);
  const [chatPosition, setChatPosition] = useState(null);
  const [activeButton, setActiveButton] = useState('faq');

  // useEffect(() => {
  //   if (lastMessageRef.current) {
  //     // lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
  //     lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  //   }
  // }, [messages]);

  useEffect(() => {
    if (isLoading && loaderRef.current) {
      loaderRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (chatPosition === 'left' && lastMessageRefLeft.current) {
      lastMessageRefLeft.current.scrollIntoView({ behavior: 'smooth' });
    } else if (chatPosition === 'right' && lastMessageRefRight.current) {
      lastMessageRefRight.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isLoading, chatPosition]);


  useEffect(() => {
    if (isLoading && loaderRef.current) {
      loaderRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isLoading, chatPosition]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setChatOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setMessages([]);

    setMessages(prevMessages => [...prevMessages, systemMessage]);
    // setIcon(femaleIcon);

    const initialMessage = `Hi there, how may I assist you? I can understand and respond in English, Japanese, and Tagalog.`;
    const newMessage =
    {
      role: 'assistant',
      content: initialMessage
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);

    setIsInitialDataLoading(false);

  }, []);


  async function callOpenAiChat() {

    console.log(`Calling Open AI API with message: ${message}`);

    setIsLoading(true);

    const newMessages = [
      ...messages,
      {
        role: 'user',
        content: message
      }
    ];

    setMessages(newMessages);
    console.log('message list: ', newMessages);

    /* TO DO
      check for certain keywords in the user's message. if user is asking for specific information on products, pricing, delivieries, query the database for that information
        get the response from the database, format it, and add to messages list as role assistant then display to user. no need to call the openai api
        or, get openai to generate a response based on the information from the database before adding to messages list and showing to the user
        for example, if user asks for list of avialble shoes, get information from the database
        - then send to openai as system message (replace system message in the message array?) with instructions to generate a response based on the information from the database 
        - after the response is generated, revert the system message to the original system message

      else, call the openai api to get a response
    */

    const aiResponse = await chatWithAI(newMessages);

    setMessages(prevMessages => [
      ...prevMessages,
      {
        role: 'assistant',
        content: aiResponse
      }
    ]);

    setAiResponse(aiResponse);

    setIsLoading(false);

    setMessage('');

  }

  const handleChatClick = (position) => {
    setActiveButton('chat');
    const windowWidth = window.innerWidth;
    console.log('Window width: ', windowWidth);
    if (windowWidth > 768) {
      setChatPosition('right');
    } else {
      setChatPosition(position);
    }

    setChatOpen(true);
    console.log('CHAT position: ', position);
    console.log('CHAT open: ', chatOpen);
    setChatClosing(false);
  };

  const handleFaqClick = () => {
    setActiveButton('faq');
    setChatClosing(true);
    setTimeout(() => {
      setChatOpen(false);
      setChatClosing(false);
    }, 500);
  };

  const handleCloseChatClick = () => {
    setActiveButton('faq');
    setChatClosing(true);
    setTimeout(() => {
      setChatOpen(false);
      setChatClosing(false);
    }, 500);
  };


  return (
    <div className="App">
      <div className="App-title">
        A demo of a customer support AI chatbot for an online retail store.
      </div>

      <div className="App-column">

        <div className="Section-left">

          {chatOpen && chatPosition == 'left' ? (

            <div className={`chat-container mobile-chat ${chatOpen ? 'open' : ''} ${chatClosing ? 'close' : ''}`}>

              <div className="close-icon-container">
                <FontAwesomeIcon icon={faTimes} onClick={handleCloseChatClick} />
              </div>
              <div className="chat-window">

                <div className="chat-messages">

                  {messages.map((message, index) => (
                    message.role !== 'system' && (
                      <div key={index}
                        className={`message-row ${message.role}`}
                        ref={index === messages.length - 1 ? lastMessageRefLeft : null}
                      >
                        {message.role === 'assistant' &&
                          <FontAwesomeIcon
                            icon={faHeadset}
                            className="message-icon"
                          />
                        }
                        <div className={`message-bubble ${message.role}`} style={{ animationDelay: `${index * 0.05}s` }}>
                          <p>{message.content}</p>
                        </div>
                        {message.role === 'user' &&
                          <FontAwesomeIcon
                            icon={faUserCircle}
                            className="message-icon"
                          />
                        }
                      </div>
                    )
                  ))}
                  {
                    isLoading ? (
                      <div className='loading'>
                        <BeatLoader size={10} className='beat-loader' ref={loaderRef} />
                      </div>
                    ) : null

                  }
                </div>

                <div className="input-message-row">
                  <input
                    className="chat-input"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault(); // Prevent the default action to avoid a line break in the textarea
                        callOpenAiChat();
                      }
                    }}
                  />
                  <button className="send-button" onClick={callOpenAiChat}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>

              {/* <div className="close-button-container">
                <button className="close-button" onClick={handleCloseChatClick}>Close Chat</button>
              </div> */}

              {/* <button className="button" >Send</button>
              <button className="button" onClick={handleCloseChatClick}>Close Chat</button> */}
            </div>


          ) : (
            <div className='page-info'>

              <div className="Section-subtitle">
                Your store for everything basketball
              </div>

              <div className="Sub-section-1">

                <div className="Sub-section-2">
                  <div className="Section-title"> Hoop </div>
                  <div className="Section-title"> Town </div>
                  {/* <div className="vertical-spacer">  </div> */}
                </div>

                <div className="image-container">
                  <img src={mainImage} className='image1' alt="App logo" />
                </div>

              </div>

              <div className="product-name-row ">
                <div>JERSEYS</div>
                <div className="horizontal-spacer"></div>
                <div>WRISTBANDS</div>
                <div className="horizontal-spacer"></div>
                <div>SHOES</div>
              </div>
              <div className="product-name-row ">
                <div>KNEEPADS</div>
                <div className="horizontal-spacer"></div>
                <div>BALLS</div>
                <div className="horizontal-spacer"></div>
                <div>HEADBANDS</div>
              </div>

              <div className="vertical-spacer"></div>
              <div className="vertical-spacer"></div>

              <div className="label-box">Customer Support Page</div>
              <div className="content">
                Check out our FAQ section for answers to common questions. If you can't find what you're looking for, feel free to chat with us.
              </div>

              {/* <button className="button faq-button" onClick={handleFaqClick}>FAQ Section</button>
            <button className="button" onClick={() => handleChatClick('left')}>Chat with us</button> */}

              <button className={`button faq-button ${activeButton === 'faq' ? 'active' : ''}`} onClick={handleFaqClick}>FAQ Section</button>
              <button className={`button ${activeButton === 'chat' ? 'active' : ''}`} onClick={() => handleChatClick('left')}>Chat with us</button>

              <div className="disclaimer">
                {disclaimerMessage}
              </div>

              <div className="chat-bubble-left" onClick={() => handleChatClick('left')}>
                <FontAwesomeIcon className="chat-bubble-icon" icon={faCommentDots} size="3x" />
              </div>

            </div>

          )}



        </div>

        <div className="Section-right">
          {/* <div className="Section-subtitle">Chat Column</div> */}
          {chatOpen ? (
            <div className={`chat-container ${chatOpen ? 'open' : ''} ${chatClosing ? 'close' : ''}`}>

              <div className="close-icon-container">
                <FontAwesomeIcon icon={faTimes} onClick={handleCloseChatClick} />
              </div>
              <div className="chat-window">

                <div className="chat-messages">

                  {messages.map((message, index) => (
                    message.role !== 'system' && (
                      <div key={index}
                        className={`message-row ${message.role}`}
                        ref={index === messages.length - 1 ? lastMessageRefRight : null}
                      >
                        {message.role === 'assistant' &&
                          <FontAwesomeIcon
                            icon={faHeadset}
                            className="message-icon"
                          />
                        }
                        <div className={`message-bubble ${message.role}`} style={{ animationDelay: `${index * 0.05}s` }}>
                          <p>{message.content}</p>
                        </div>
                        {message.role === 'user' &&
                          <FontAwesomeIcon
                            icon={faUserCircle}
                            className="message-icon"
                          />
                        }
                      </div>
                    )
                  ))}
                  {
                    isLoading ? (
                      <div className='loading'>
                        <BeatLoader size={10} className='beat-loader' ref={loaderRef} />
                      </div>
                    ) : null

                  }
                </div>

                <div className="input-message-row">
                  <input
                    className="chat-input"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault(); // Prevent the default action to avoid a line break in the textarea
                        callOpenAiChat();
                      }
                    }}
                  />
                  <button className="send-button" onClick={callOpenAiChat}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>

              {/* <div className="close-button-container">
                <button className="close-button" onClick={handleCloseChatClick}>Close Chat</button>
              </div> */}

              {/* <button className="button" >Send</button>
              <button className="button" onClick={handleCloseChatClick}>Close Chat</button> */}
            </div>

          ) : (
            // <div className="chat-bubble" onClick={handleChatClick}>Chat bubble goes here</div>
            <div>
              <div className="faq-title">Frequently Asked Questions</div>

              <div className="faq-container">
                <details open>
                  <summary className="faq-question">What products to you carry?</summary>
                  <p className="faq-answer">We sell NBA jerseys, shorts, shoes, basketballs, basketball hoops, socks, headbands, writsbands, kneepads, and shoulder pads</p>
                </details>
                <details>
                  <summary className="faq-question">What is your return policy?</summary>
                  <p className="faq-answer">We strive to ensure our customers are satisfied with their purchases. However, if for any reason you're not completely satisfied, our return policy lasts for 30 days. If 30 days have gone by since your purchase, unfortunately, we can't offer you a refund or exchange. To be eligible for a return, your item must be unused and in the same condition that you received it. Please note that the cost of return shipping is at the customer's expense. This will not be reimbursed, so we recommend obtaining proof of postage. For more information, please contact our customer service team</p>
                </details>
                <details>
                  <summary className="faq-question">Do you have a physical store?</summary>
                  <p className="faq-answer">We're an online-only retailer, which means we unfortunately do not have a physical store you can visit. All our products are available through our website, and we're open round-the-clock for your convenience. The benefit of being online is that we're always just a click away!

                    We proudly ship to several regions which include Southeast Asia, Australia, and New Zealand. You can browse our extensive online catalog and have items delivered straight to your doorstep. It's easier than ever to order your favorite products from the comfort of your home! For any additional questions, feel free to reach out to our customer service team.</p>
                </details>
                <details>
                  <summary className="faq-question">How much do you charge for deliveries?</summary>
                  <p className="faq-answer">We offer free delivery for all purchases exceeding USD 200 (or its equivalent in your local currency). This will be automatically applied at checkout when your order meets the value required.

                    For orders below USD 200, delivery charges may apply. These charges vary depending on the delivery location and the weight of the package.

                    Please note, the final delivery cost will be calculated and displayed at checkout prior to making payment. We ensure our delivery prices are as transparent and as reasonable as possible. For further queries regarding our delivery policy, don't hesitate to contact our customer service team.</p>
                </details>
                <details>
                  <summary className="faq-question">Are your products authentic?</summary>
                  <p className="faq-answer">We recognize the importance of purchasing authentic, high-quality products. Rest assured that every item we sell is 100% authentic and sourced directly from the manufacturer or authorized distributors. We take product authenticity very seriously and we have strict measures in place to ensure that only genuine products are listed on our platform. If you have any concerns or queries about the authenticity of any product listed on our site, please don't hesitate to contact our customer service team for further assistance. Our aim is to provide you with the highest quality products and the best online shopping experience.</p>
                </details>
              </div>

              <div className="chat-bubble-right" onClick={() => handleChatClick('right')}>
                <FontAwesomeIcon className="chat-bubble-icon" icon={faCommentDots} size="3x" />
              </div>
            </div>
          )}
        </div>

      </div>

    </div>
  );
}

export default App;